export const EDITOR_ELEMENTS_TYPES = {
  HEADING: 'heading',
  PARAGRAPH: 'paragraph',
  BULLET_LIST: 'bulletList',
  ORDERED_LIST: 'orderedList',
  VALIDATION_CHECKBOX: 'validationCheckbox',
  RADIO_BUTTON: 'radioButton',
  RADIO_BUTTON_GROUP: 'radioButtonGroup',
  TEXTBOX: 'textbox',
  TEXT: 'text',
  HARD_BREAK: 'hardBreak',
  IMAGE: 'image',
  EXTERNAL_VIDEO: 'externalVideo',
  IMAGE_UPLOADER: 'imageUploader',
  SCALE_CONTINUES: 'scale_continues',
  NUMBER_INPUT: 'number_input',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  READ_TEXT: 'read_text',
  CONDITIONAL_RADIO_BUTTON: 'conditionalRadioButton',
  CONDITIONAL_CHECKBOX: 'conditionalCheckbox',
  CONDITIONAL_CONTENT: 'conditionalContent',
  DROP_DOWN: 'drop_down',
  SCALE: 'scale_ratio_button',
};

export const RESEARCH_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  STARTED: 'started',
};

export const PARTICIPANT_STATUS = {
  APPROVED: 'approved',
  DENIED: 'denied',
};

export default {
  EDITOR_ELEMENTS_TYPES,
  RESEARCH_STATUS,
  PARTICIPANT_STATUS,
};
